@import 'default.less';

/*
Colors
**/

@white: #FFFFFF;
@blue: #1B2A61;
@sky: #D0DDED;
@silver: #F0F5FB;
@red: #C50E0E;
@orange: #f56410;
@green: #0EC26C;
@rock: #C4C4C4;
@gray: #969696;
@black: #666666;

.gradient {
	background: rgba(16,209,154,1);
	background: -moz-linear-gradient(-75deg, rgba(16,209,154,1) 0%, rgba(12,115,210,1) 100%);
	background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(16,209,154,1)), color-stop(100%, rgba(12,115,210,1)));
	background: -webkit-linear-gradient(-75deg, rgba(16,209,154,1) 0%, rgba(12,115,210,1) 100%);
	background: -o-linear-gradient(-75deg, rgba(16,209,154,1) 0%, rgba(12,115,210,1) 100%);
	background: -ms-linear-gradient(-75deg, rgba(16,209,154,1) 0%, rgba(12,115,210,1) 100%);
	background: linear-gradient(165deg, rgba(16,209,154,1) 0%, rgba(12,115,210,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#10d19a', endColorstr='#0c73d2', GradientType=1 );
}

/*
Structure
**/

body {
	background-color: @silver;
}

*:focus {
	outline: none;
}

.row {
	
	&.expanded {
		max-width: 100%;
	}
}

[class*=grid-] {
	margin: 0 -.625rem;
}

/*
Fetures
**/

.opacity {
	opacity: 1;
	.vendor(transition, opacity .5s ease-in-out);

	&:hover {
		opacity: .6;
		text-decoration: none;
	}
}

.opacity-translate {
	opacity: 1;
	.vendor2(transition, opacity .5s ease-in-out, transform .5s);

	&:hover {
		opacity: .6;
		text-decoration: none;
		.vendor(transform, translateY(-2px));
	}
}

.default-button {
	background-color: @green;
	border-radius: 30px;
	text-transform: uppercase;
	text-align: center;
	color: @white;
	display: inline-block;
	font-size: 12px;
    padding: 10px 20px;
    border: none;
    font-weight: 500;
    .opacity;

    &.translate {
    	.opacity-translate;
	}
	
	&.red {
		background-color: @red;
	}
	&.orange {
		background-color: @orange;
	}
	&.sky {
		background-color: @sky;
		color: @gray;
	}

	&.gradient {
		.gradient;
	}

	&.full {
		width: 100%;
		padding: 13px 20px;
	}
}

.spacing {
	padding: 60px 0;
}

form {

	input[type], textarea, select {
		border: none;
		height: 35px;
		padding: 0 0 0 16px;
		border-radius: 30px;
		font-size: 13px;
		display: block;
		width: 100%;
		margin-bottom: 16px;
		background-color: @white;
		box-shadow: 0px 2px 8px 0px rgba(228, 228, 228, 1);

		&:focus {
			border: none;
			outline: 0;
		}

		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: @gray;
			font-size: 13px;
		}

		&::-moz-placeholder { /* Firefox 19+ */
			color: @gray;
			font-size: 13px;
		}

		&:-ms-input-placeholder { /* IE 10+ */
			color: @gray;
			font-size: 13px;
		}
	}

	input[type="checkbox"] {
		width: 14px;
		height: 14px;
		display: inline-block;
		box-shadow: none;
	}

	textarea {
		height: 150px;
	}
}

/*
Slick slider
**/

.slider {
	position: relative;

	.slick-arrow {
		position: absolute;
		top: 50%;
		font-size: 0;
		z-index: 2;
		border: none;
		background-color: transparent;
		.vendor(transform, translateY(-50%));

		&::before {
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			font-size: 16px;
			color: @blue;
			opacity: .4;	
			.vendor(transition, opacity .5s);
		}

		&.slick-prev {
			left: -40px;

			@media (max-width: 80em) {
				left: 0;
			}

			&::before {
				content: "\f060";
			}
		}

		&.slick-next {
			right: -40px;

			@media (max-width: 80em) {
				right: 0;
			}

			&::before {
				content: "\f061"; 
			}			
		}

		&:hover {

			&::before {
				opacity: .8;
			}
		}
	}

	.slick-dots {
		list-style: none;
		margin: 0;
		position: absolute;
		bottom: 20px;
		left: 50%;
		.flex;
		.vendor(transform, translateX(-50%));


		> li {
			margin: 0 5px;

			button {
				font-size: 0;
				border: none;
				background-color: transparent;
				.flex-centered;

				&::before {
					content: '';
					width: 10px;
					height: 10px;
					background-color: @silver;
					opacity: .5;
					display: block;
					border-radius: 50%;
				}
			}

			&.slick-active {

				button {

					&::before {
						opacity: 1;
					}
				}
			}
		}		
	}
}

/*
Mobile menu
**/

@media (max-width: 70em) {

	.wrap-page {
		padding-top: 70px;
	}
}


/*
Responsive Table
*/

.wrap-table table {
	border-collapse: collapse;
	margin: 0;
	padding: 0;
	width: 100%;
	table-layout: fixed;
}

@media screen and (max-width: 600px) {
	.wrap-table table {
		border: 0;

		caption {
			font-size: 1.3em;
		}

		thead {
			border: none;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
		}

		tr {
			border-bottom: 3px solid #ddd;
			display: block;
			margin-bottom: .625em;

			td {
				border-bottom: 1px solid #ddd;
				display: block;
				font-size: .8em;
				text-align: right;

				&::before {
					/*
					* aria-label has no advantage, it won't be read inside a table
					content: attr(aria-label);
					*/
					content: attr(data-label);
					float: left;
					font-weight: bold;
					text-transform: uppercase;
				}

				&:last-child {
					border-bottom: 0;
				}
			}
		}
	}
}

.text-right {
	text-align: right;
}

