body:not(.help-page) {

	@import 'snippets/fonts.less';
	@import 'snippets/typography.less';
	@import 'snippets/basic.less';
	
	
	.status-mixin {
		display: block;
		width: 40px;
		height: 16px;
		position: relative;
		border-radius: 25px;
		background-color: @rock;
	
		&:hover {
			cursor: pointer;
		}
	
		span {
			position: absolute;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			left: 3px;
			top: 3px;
			background-color: @green;
			transition: left .5s;
		}			
	
		&.inactive {
	
			span {
				background-color: @red;
				left: 26px;
			}
		}
	}
	
	
	/*
	Login
	*/
	
	.wrap-login {
		position: relative;
		width: 100%;
		height: 100vh;
		.flex;
		.flex-column;
		.justify-between;
		.gradient;
	
		&::before {
			position: absolute;
			left: 50%;
			top: 0;
			transform: translateX(-50%);
			content: url('../images/watermark.png');
			z-index: 1;
		}
	
		.head {
			position: relative;
			z-index: 2;
	
			.logo {
				padding: 12vh 0 0 0;
				.flex-centered;
			}
		}
	
		.content {
			position: relative;
			z-index: 2;
	
			h3 {
				text-align: center;
				color: @white;
				text-transform: uppercase;
				font-size: 20px;
				margin-bottom: 40px;
			}
	
			form {
	
				input[type] {
					height: 55px;
					color: @white;
					background-color: rgba(255, 255, 255, .25);
					box-shadow: none;
					padding-left: 70px;
	
					&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
						color: @white;
					}
	
					&::-moz-placeholder { /* Firefox 19+ */
						color: @white;
					}
	
					&:-ms-input-placeholder { /* IE 10+ */
						color: @white;
					}
				}
	
				.wrap-input {
					position: relative;
	
					span {
						position: absolute;
						width: 45px;
						height: 45px;
						left: 6px;
						top: 5px;
						border-radius: 50%;
						margin: 0;
						background-color: rgba(255, 255, 255, .25);
						.flex-centered;
					}
				}
	
				button[type] {
					font-size: 16px;
					width: 100%;
					height: 55px;
					margin-bottom: 30px;
					background-color: rgba(255, 255, 255, .5);
				}
	
				.bottom {
					padding: 0 20px;
					.flex;
					.justify-between;
					.align-center;
	
					label {
						color: @white;
						font-size: 13px;
						.flex-centered;
	
						input[type="checkbox"] {
							margin: 0 5px 0 0;
							background-color: @white;
							border: 0;
							height: 20px;
						}
					}
	
					a {
						color: @white;
						font-size: 13px;
						.opacity;
					}
				}
			}
		}
	
		.footer {
			padding: 5vh 0;
	
			p {
				text-align: center;
				color: @white;
				font-size: 13px;
	
				a {
					color: @white;
					font-weight: 600;
					.opacity;
				}
			}
		}
	}
	
	/*
	Pages
	*/
	
	.navigation {
		width: 235px;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 9999;
	
		@media (max-width: 70em) {
			display: none;
		}
	
		.head {
			padding: 30px;
			height: 125px;
			border-radius: 0 20px 0 0;
			.gradient;
		}
	
		.content {
			height: ~"calc(100vh - 125px)";
			background-color: @blue;
			position: relative;
			padding: 0;
	
			&::before {
				content: '';
				position: absolute;
				height: 30px;
				width: 100%;
				background-color: @blue;
				border-radius: 0 20px 0 0;
				left: 0;
				top: -30px;
			}
	
			.photo {
				.flex-centered;
				.flex-column;
	
				> div {
					width: 60px;
					margin-bottom: 10px;
	
					figure {
						border-radius: 50%;
					}
				}
	
				p {
					color: @white;
					font-size: 13px;
				}
			}
	
			.menu {
				padding-left: 25px;
				list-style: none;
	
				li {
	
					a {
						color: @white;
						font-size: 13px;
						display: block;
						padding: 10px 0 10px 20px;
						border-radius: 30px 0 0 30px;
						.opacity;
						.flex;
						.align-center;
	
						.wrap-image {
							width: 40px;
							height: 22px;
							.flex;
							.align-center;
						}
	
						&.active {
							background-color: @silver;
							color: @black;
							position: relative;
	
							&::before {
								content: url('../images/menu-detail-top.svg');
								position: absolute;
								right: 0;
								top: -24px;
							}
	
							&::after {
								content: url('../images/menu-detail-bottom.svg');
								position: absolute;
								right: 0;
								bottom: -31px;
							}
	
							.wrap-image {
	
								img {
									filter: brightness(0.5);
								}
							}
						}
					}
				}
			}
	
			.logout {
				position: absolute;
				left: 30px;
				bottom: 30px;
				color: @white;
				font-size: 13px;
				.flex-centered;
				.opacity;
	
				img {
					margin-right: 15px;
				}
			}
		}
	}
	
	.wrap-page {
		.flex;
	}
	
	.wrap-content {
		width: ~"calc(100% - 235px)";
		height: 100vh;
		position: fixed;
		right: 0;
		top: 0;
		overflow-y: scroll;
	
		@media (max-width: 70em) {
			width: 100%;
		}
	
		.notices-bar {
			padding-top: 20px;
			margin-bottom: 40px;
	
			.inner {
				.flex;
				.justify-between;
	
				.warning {
					.flex-centered;
	
					p {
						margin: 0 10px;
						font-weight: 600;
	
						strong {
							color: @red;
						}
					}
				}
	
				.help {
					.opacity;
					.flex-centered;
	
					p {
						margin: 0 10px 0 0;
					}
				}
			}
		}
	
		.filters-bar {
	
			input[type], select {
				height: 45px;
			}
	
			label {
				height: 45px;
				color: @gray;
				.flex;
				.align-center;
	
				input[type="checkbox"] {
					margin: 0 10px 0 0;
					height: 16px;
				}
			}
	
			.default-button {
				padding: 15px 30px;
			}
		}
	}
	
	.wrap-table {
	
		h3 {
			font-size: 18px;
		}
	
		.subtitles {
			list-style: none;
			.flex;
			.justify-end;
			.align-center;
	
			li {
				position: relative;
				padding-left: 20px;
				margin-left: 40px;
	
				span {
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					width: 6px;
					height: 6px;
					border-radius: 50%;
				}
			}
		}
	
		.text-circle {
			margin-bottom: 40px;
	
			p {
				text-transform: uppercase;
				text-align: center;
			}
		}
	
		table {
			box-shadow: 0px 2px 8px 0px rgba(228, 228, 228, 1);
	
			tr {
				
				th {
					background-color: @sky;
					text-align: left;
					padding: 14px 10px;
					font-weight: 500;
					color: @black;
	
					&:first-child {
						border-radius: 25px 0 0 0;
						width: 120px;
						padding-left: 40px;
					}
	
					&.small {
						border-radius: 0 25px 0 0;
						width: 80px;
					}
					
					&.minor {
						width: 90px;
					}
	
					&.medium {
						width: 150px;
					}
				}
	
				td {
					position: relative;
					padding: 10px 10px;
	
					&:first-child {
						padding-left: 40px;
					}
	
					.icon {
						filter: grayscale(100%);
	
						&.active {
							filter: grayscale(0%);
						}
					}
	
					.subtitle-item {
						padding-left: 20px;
						position: relative;
						
						span {
							position: absolute;
							left: 0;
							top: 4px;
							width: 10px;
							height: 10px;
							border-radius: 50%;
							
						}
	
						@media (max-width: 40em) {
							padding-left: 80px;
	
							span {
								left: 60px;
							}
						}
					}
	
					.status {
						.status-mixin();
					}
	
					.share {
						position: relative;
						color: @gray;
						.flex;
						.align-center;
	
						img {
							margin-right: 10px;
						}
	
						> div {
							display: none;
							top: -132px;
							left: 0;
							position: absolute;
							padding: 20px;
							background-color: @white;
							box-shadow: 0px 0px 4px 1px rgba(179, 179, 179, 0.24);
	
							> a {
								display: block;
								position: relative;
								padding-left: 20px;
								.opacity;
	
								&:first-child {
									margin-bottom: 20px;
								}
	
								h4 {
									font-size: 14px;
									margin: 0;
									font-weight: 400;
								}
	
								h5 {
									font-size: 13px;
									margin: 0;
									font-weight: 400;
								}
	
								i {
									position: absolute;
									left: 0;
									top: 0;
									color: @green;
								}
							}
						}					
	
						&:hover {
							cursor: pointer;
	
							> div {
								display: block;
							}
						}
					}
	
					.settings {
						display: block;
						.opacity;
					}
				}
	
				&:nth-child(4n+1) {
	
					td {
						background-color: #F7FAFD;
					}
				}		
	
				&.wrap-subdrop {
					background-color: #EBF0F5;
					box-shadow: inset 0px 42px 4px -42px rgba(179, 179, 179, 0.24);
	
					td {
						padding: 0 40px;
	
						> div {
							display: none;
							position: relative;
							padding: 10px 0;
	
							select {
								margin: 0;
							}
	
							.close {
								position: absolute;
								right: 0;
								top: -50px;
							}
						}
					}
				}
			}
	
			&.sequential {
	
				tr {
					
					&:nth-child(odd) {
	
						td {
							background-color: #F7FAFD;
						}
					}
	
					td {
						padding-top: 30px;
						padding-bottom: 30px;
					}
				}
			}
		}
	}
	
	
	.wrap-header-mobile {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		padding: 10px 0;
		z-index: 9;
		.gradient;
	
		.toggle-menu {
			font-size: 24px;
			color: @white;
			.opacity;
		}
	
		.logo {
			
			img {
				display: block;
				height: 50px;
				margin: auto;
			}
		}
	
		.mobile-menu {
			position: fixed;
			background-color: @blue;
			top: -100%;
			left: 0;
			width: 100%;
			height: 100%;
			overflow-y: scroll;
			.vendor(transition, top .5s);
	
			&.opened {
				top: 0;
			}
	
			.toggle-menu {
				padding: 30px 0 0 0;
				color: @white;
				z-index: 2;
			}
	
			.navigation {
				list-style: none;
				margin: 0;
				display: block;
				position: relative;
				width: 100%;
	
				.content {
					width: 100%;
	
					.menu {
						list-style: none;
		
						li {
							display: block;
							width: 100%;
			
							a {
								color: @white;
								text-transform: uppercase;
								font-size: 14px;
								font-weight: 500;
								display: block;
								text-align: center;
								padding: 15px 0;
								.flex-centered;
								.opacity;
			
								i {
									margin-right: 5px;
								}
	
								&.active {
									color: @blue;
									border-radius: 25px;
									border-bottom: 0;
									&::after {
										display: none;
									}
	
									&::before {
										display: none;
									}
								}
							}
			
							&:not(:last-child) {
								
								a {
									border-bottom: 1px solid @silver;
									border-radius: 0;
								}
							}
						}
					}
				}
	
				.head {
					display: none;
				}
			}
		}
	}
	
	.wrap-report-head {
		
		ul {
			padding: 20px 0;
			border-top: 1px solid @gray;
			border-bottom: 1px solid @gray;
			margin: 0 0 40px 0;
	
			li {
				padding: 0;
	
				h4 {
					font-size: 14px;
					margin: 0;
				}
	
				p {
					margin: 0;
				}
			}
			
		}
	}
	
	a.checkbox-status {
		.status-mixin();
	}

	i.fa, i.fas, i.far, i.fab {
		font-style: normal!important;
	}
}	
.warning {
	p {
		color: red!important;
	}
}

.text-center {
	text-align: center;
}