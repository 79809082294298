/*
All snippets
**/

.vendor(@property, @value) {
	-webkit-@{property}: @value;
	-moz-@{property}: @value;
	-ms-@{property}: @value;
	@{property}: @value;
}

.vendor2(@property, @value, @value2) {
	-webkit-@{property}: @value, @value2;
	-moz-@{property}: @value, @value2;
	-ms-@{property}: @value, @value2;
	@{property}: @value, @value2;
}

.relative {
	position: relative;
}

.text-center {
	text-align: center;
}

/*
Icomoon
**/

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
Responsive image canvas
**/

figure.image {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	img {
		visibility: hidden;
	}
}

figure.image.contain {
	background-size: contain;
}

.image-item {
	height: auto;
}

img {
	max-width: 100%;
}


/*
Flexbox snippets
**/

.flex {
	.vendor(display, flex);
}

.align-center {
	.vendor(align-items, center);
}

.align-start {
	.vendor(align-items, flex-start);
}

.align-end {
	.vendor(align-items, flex-end);
}

.justify-center {
	.vendor(justify-content, center);
}

.justify-evenly {
	.vendor (justify-content, space-evenly);
}

.justify-between {
	.vendor (justify-content, space-between);
}

.justify-around {
	.vendor (justify-content, space-around);
}

.justify-end {
	.vendor (justify-content, flex-end);
}

.justify-start {
	.vendor (justify-content, flex-start);
}

.flex-column {
	.vendor(flex-direction, column);
}

.flex-wrap {
	.vendor(flex-wrap, wrap);
}

.flex-centered {
	.flex;
	.align-center;
	.justify-center;
}

.tablet-column {

	@media (max-width: 70em) {
		.vendor(flex-direction, column);
	}
}

.mobile-column {
	
	@media (max-width: 40em) {
		.vendor(flex-direction, column);
	}
}