@font-color: #969696;
@title-color: #666666;
@font: 'Rubik', sans-serif;

a {
    font-family: @font;
    text-decoration: none;
    line-height: inherit; 
    color: #2d2f92;

    &:hover {
        text-decoration: underline;
    }
}

a img {
    border: none; 
}

/* Default paragraph styles */
p, span {
    font-family: @font;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 12px;
    text-align: left;
    text-rendering: optimizeLegibility;
    color: @font-color;
    &[class*=swal2] {
        all:unset;
    }
}

form {

    input[type], select, span, label, textarea  {
        font-family: @font;
        font-size: 14px;
    }

    button[type] {
        font-family: @font;        
    }
}

table {

    tr {

        th, td {
            font-family: @font;
            font-size: 14px;
            color: @font-color;
        }
    }
}

/* Default header styles */
h1, h2, h3, h4, h5, h6 {
    font-family: @font;
    text-rendering: optimizeLegibility;
    font-style: normal;
    margin: 0 0 16px 0;
    line-height: 1.4;
    color: @title-color;
}

/* Helpful Typography Defaults */
em,
i {
    font-style: italic;
    line-height: inherit;
}

strong,
b {
    font-weight: bold;
    line-height: inherit; 
}

small {
    font-size: 60%;
    line-height: inherit; 
}

/* Lists */
ul,
ol,
dl {
    font-family: @font;
    list-style-position: outside;
    margin-bottom: 12px;
    line-height: 1.6;
    font-size: 14px;
    color: @font-color;
}

code {
    font-family: Consolas, "Liberation Mono", Courier, monospace;
    font-weight: normal;
    color: #333333;
    background-color: #f8f8f8;
    border-width: 1px;
    border-style: solid;
    border-color: #e0e0e0;
    padding: 0.125rem 0.3125rem 0.0625rem;
}
